import { trackEvent, trackUserInteraction } from "@/services/analytics-adapter";
import { CollectTermsAcceptanceContract } from "@/services/core-api-adapter";
import { FaceIconBrandLarge } from "@/theme/icons";
import { Stack, Typography, Checkbox, Link } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import FullScreenIframeDialog from "@/components/FullScreenIframeDialog";
import TermsConsentModal from "./components/TermsConsentModal";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";

interface CollectTermsProps {
  onTermsAccept: () => void;
  contractList: CollectTermsAcceptanceContract[];
}

function findTermsAndConditionsContractFromList(
  contracts: CollectTermsAcceptanceContract[]
): CollectTermsAcceptanceContract | null {
  return (
    contracts.find((contract: CollectTermsAcceptanceContract) => {
      return (
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM" ||
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM_ZA" ||
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM_MZ" ||
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM_UG" ||
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM_COU" ||
        contract.ContractType === "TERMS_AND_CONDITIONS" ||
        contract.ContractType === "COOKIE_POLICY" ||
        contract.ContractType === "PRIVACY_POLICY"
      );
    }) || null
  );
}

function findProductTermsAndConditionsContractFromList(
  contracts: CollectTermsAcceptanceContract[]
): CollectTermsAcceptanceContract | null {
  return (
    contracts.find((contract: CollectTermsAcceptanceContract) => {
      return contract.ContractType === "TERMS_AND_CONDITIONS_PRODUCT";
    }) || null
  );
}

export default function CollectTerms({
  onTermsAccept,
  contractList,
}: CollectTermsProps) {
  const { t } = useTranslation();
  const termsAndConditionContract =
    findTermsAndConditionsContractFromList(contractList);

  const productTermsAndConditionContract =
    findProductTermsAndConditionsContractFromList(contractList);

  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState<boolean>(false);

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] =
    useState<boolean>(false);

  const [
    isProductTermsAndConditionsAccepted,
    setIsProductTermsAndConditionsAccepted,
  ] = useState<boolean>(false);

  const [isTermsConsentModalOpen, setIsTermsConsentModalOpen] =
    useState<boolean>(false);
  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);
  const [
    isProductTermsAndConditionsDialogOpen,
    setIsProductTermsAndConditionsDialogOpen,
  ] = useState(false);

  function onTermsLinkClick(event: any) {
    event.preventDefault();
    setIsTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.termsAndConditionsLinkOpened",
      source: "memberRequiredActions",
    });

    trackUserInteraction({
      linkText: "T&Cs Screen | terms and conditions",
      linkIntent: "informational",
      linkScope: "button",
    });
  }

  function onProductTermsLinkClick(event: any) {
    event.preventDefault();
    setIsProductTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.productTermsAndConditionsLinkOpened",
      source: "memberRequiredActions",
    });

    trackUserInteraction({
      linkText: "T&Cs Screen | product terms and conditions",
      linkIntent: "informational",
      linkScope: "button",
    });
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }

  function onProductTermsDialogClose() {
    setIsProductTermsAndConditionsDialogOpen(false);
  }

  function onTermsAndConditionsCheckboxChange(e: any) {
    e.preventDefault();
    setIsTermsAndConditionsAccepted(e.target.checked);
  }

  function onProductTermsAndConditionsCheckboxChange(e: any) {
    e.preventDefault();
    setIsProductTermsAndConditionsAccepted(e.target.checked);
  }

  function onMoreInfoButtonClick() {
    setIsTermsConsentModalOpen(true);
    trackUserInteraction({
      linkText: "T&Cs Screen | Why do we need your consent for this?",
      linkIntent: "informational",
      linkScope: "button",
    });
  }

  useEffect(() => {
    const canEnableSubmit =
      (isTermsAndConditionsAccepted && !productTermsAndConditionContract) ||
      (isProductTermsAndConditionsAccepted && !termsAndConditionContract) ||
      (isProductTermsAndConditionsAccepted && isTermsAndConditionsAccepted);

    setIsSubmitButtonEnabled(canEnableSubmit);
  }, [
    isProductTermsAndConditionsAccepted,
    isTermsAndConditionsAccepted,
    productTermsAndConditionContract,
    termsAndConditionContract,
  ]);

  return (
    <>
      <Stack spacing={4} justifyContent="flex-end" sx={{ pb: 2 }}>
        <Stack spacing={2}>
          <FaceIconBrandLarge />
          <Typography variant="h3">
            {t("CollectTermsAcceptance.title")}
          </Typography>
        </Stack>

        {termsAndConditionContract && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Checkbox
              inputProps={{
                "aria-label": t(
                  "CollectTermsAcceptance.termsAndConditions.checkBox.label"
                ),
              }}
              sx={{ padding: 0 }}
              value={isTermsAndConditionsAccepted}
              onChange={onTermsAndConditionsCheckboxChange}
            />
            <Typography>
              <Trans i18nKey="CollectTermsAcceptance.termsAndConditions.title">
                <Link href="/" onClick={onTermsLinkClick}></Link>
              </Trans>
            </Typography>
          </Stack>
        )}

        {productTermsAndConditionContract && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Checkbox
              inputProps={{
                "aria-label": t(
                  "CollectTermsAcceptance.productTermsAndConditions.checkBox.label"
                ),
              }}
              sx={{ padding: 0 }}
              value={isProductTermsAndConditionsAccepted}
              onChange={onProductTermsAndConditionsCheckboxChange}
            />
            <Typography>
              <Trans
                i18nKey="CollectTermsAcceptance.productTermsAndConditions.title"
                values={{
                  productName: productTermsAndConditionContract?.Description,
                }}
              >
                <Link href="/" onClick={onProductTermsLinkClick}></Link>
              </Trans>
            </Typography>
          </Stack>
        )}

        <Stack spacing={4} mt={2}>
          <Stack
            onClick={onMoreInfoButtonClick}
            component="button"
            sx={{
              cursor: "pointer",
              border: "none",
              background: "transparent",
            }}
            color="neutral"
            aria-label={t(
              "CollectTermsAcceptance.termsAndConditions.moreInfoModal.title"
            )}
          >
            <Typography
              textAlign="left"
              color="primary"
              variant="body1"
              fontWeight={600}
            >
              {t(
                "CollectTermsAcceptance.termsAndConditions.moreInfoModal.title"
              )}
            </Typography>
          </Stack>

          <ButtonWithAnalytics
            page="T&Cs Screen"
            text={t("common.continueButton")}
            intent="navigational"
            onClick={onTermsAccept}
            color="primary"
            disabled={!isSubmitButtonEnabled}
            fullWidth
          >
            {t("common.continueButton")}
          </ButtonWithAnalytics>
        </Stack>
      </Stack>

      <TermsConsentModal
        isOpen={isTermsConsentModalOpen}
        onClose={() => setIsTermsConsentModalOpen(false)}
      />

      {termsAndConditionContract && (
        <FullScreenIframeDialog
          title={t(
            `CollectTermsAcceptance.termsAndConditions.${termsAndConditionContract?.ContractType}.documentTitle` as any
          )}
          isOpen={isTermsAndConditionsDialogOpen}
          onClose={onTermsDialogClose}
          url={termsAndConditionContract?.FileUri || ""}
        />
      )}

      {productTermsAndConditionContract && (
        <FullScreenIframeDialog
          title={t(
            "CollectTermsAcceptance.termsAndConditions.TERMS_AND_CONDITIONS_PRODUCT.documentTitle"
          )}
          isOpen={isProductTermsAndConditionsDialogOpen}
          onClose={onProductTermsDialogClose}
          url={productTermsAndConditionContract.FileUri}
        />
      )}
    </>
  );
}
