import { Box, Button, Stack, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import FullscreenBackground from "@/components/FullscreenBackground";
import IconLoader from "@/components/IconLoader";
import { trackEvent } from "@/services/analytics-adapter";
import { convertPxToRem } from "@/utils";
import theme from "@/theme";

interface PathologyPromptProps {
  onBackButtonClick: () => void;
  onContinueButtonClick: () => void;
}

function StepsCounter({ stepCounter }: any) {
  return (
    <Box
      sx={{
        position: "relative",
        height: 30,
        width: 30,
        alignSelf: "center",
      }}
    >
      <IconLoader
        icon="HandDrawnCircleIcon"
        sx={{
          color: "accent2.light",
          fontSize: convertPxToRem(30),
          alignSelf: "center",
          position: "absolute",
          left: "0",
        }}
      />
      <Typography
        sx={{
          fontWeight: 700,
          position: "relative",
          zIndex: 1,
          top: "50%",
          left: "50%",
          textAlign: "center",
          transform: "translate(-50%, -50%)",
          color: "accent2.dark",
          mr: 2,
        }}
      >
        {stepCounter}
      </Typography>
    </Box>
  );
}

export function PathologyPrompt({
  onContinueButtonClick,
  onBackButtonClick,
}: PathologyPromptProps) {
  const { t } = useTranslation();

  function onContinue() {
    trackEvent({
      event: "action.nextStepsContinue",
      source: "Church Of Uganda Medical Assistance",
    });
    onContinueButtonClick();
  }

  function onBack() {
    onBackButtonClick();
  }

  return (
    <>
      <Stack p={2} justifyContent="space-between" flexGrow={1} minHeight="100%">
        <Stack
          color="neutral.50"
          spacing={4}
          sx={{
            height: "100%",
          }}
        >
          <Typography variant="h1" color="info.main" textAlign="center">
            {t("PathologyPrompt.title")}
          </Typography>
          <Stack spacing={4}>
            <Stack
              bgcolor="neutral.50"
              borderRadius={theme.shape.borderRadius}
              p={4}
              spacing={2}
            >
              <Typography variant="h2">
                {t("PathologyPrompt.steps.title")}
              </Typography>

              <Stack direction="row">
                <StepsCounter stepCounter={1} />
                <Typography ml={2} variant="body1">
                  <Trans i18nKey={"PathologyPrompt.steps.1.title"} />
                </Typography>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={2} />
                <Typography ml={2} variant="body1">
                  <Trans i18nKey={"PathologyPrompt.steps.2.title"} />
                </Typography>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={3} />
                <Box>
                  <Typography ml={2} variant="body1">
                    <Trans i18nKey={"PathologyPrompt.steps.3.title"} />
                  </Typography>
                  <Typography ml={2} variant="body2">
                    <Trans i18nKey={"PathologyPrompt.steps.3.subtitle"} />
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row">
                <StepsCounter stepCounter={4} />
                <Typography ml={2} variant="body1">
                  <Trans i18nKey={"PathologyPrompt.steps.4.title"} />
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={onBack}
                color="info"
                fullWidth
                variant="outlined"
              >
                {t("common.backButton")}
              </Button>
              <Button
                size="small"
                fullWidth
                component="a"
                target="_blank"
                color="info"
                onClick={onContinue}
              >
                {t("common.continueButton")}
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <FullscreenBackground color="primary.main" />
      </Stack>
    </>
  );
}
