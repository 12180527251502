import {
  trackFormEvent,
  AnalyticsEvent,
  trackEvent,
} from "@/services/analytics-adapter";
import {
  CollectTermsAcceptanceContract,
  submitMultipleContractAgreements,
} from "@/services/core-api-adapter";
import { useState, useEffect } from "react";
import CollectTerms from "@/components/CollectTerms";
import FullscreenLoadingIndicator from "@/components/FullscreenLoadingIndicator";

interface CollectTermsAcceptanceProps {
  onSubmit: () => void;
  data: CollectTermsAcceptanceContract[];
}

export default function CollectTermsAcceptance({
  data,
  onSubmit,
}: CollectTermsAcceptanceProps) {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Collect terms and conditions",
    });
  }, []);

  function onFormSubmit() {
    setIsSubmittingForm(true);
    submitMultipleContractAgreements(
      data.map((contractAgreement) => {
        return {
          contractType: contractAgreement.ContractType,
          version: String(contractAgreement.Version),
        };
      })
    )
      .catch(() => setIsSubmittingForm(false))
      .then(() => {
        setIsSubmittingForm(false);
        onDone();
      });
  }

  function onDone() {
    trackEvent({
      event: "action.termsAndConditionsAccepted",
      source: "platformTermsAndConditions",
    });

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Collect terms and conditions",
    });

    onSubmit();
  }

  return (
    <>
      {isSubmittingForm ? (
        <FullscreenLoadingIndicator />
      ) : (
        <>
          <CollectTerms onTermsAccept={onFormSubmit} contractList={data} />
        </>
      )}
    </>
  );
}
