import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import SlideUpDialog from "../../../SlideUpDialog";
import { useState } from "react";
import IconLoader from "../../../IconLoader";
import {
  buildWhatsAppUniversalLinkURL,
  convertPxToRem,
  convertSnakeCaseToSentenceCase,
} from "../../../../utils";
import { trackEvent } from "../../../../services/analytics-adapter";
import {
  ChatBotLanguageOption,
  ChatBotOption,
  submitHealthRecordEvent,
  SubmitHealthRecordEventType,
} from "../../../../services/core-api-adapter";
import { getConfigurationVariable } from "../../../../services/configuration-adapter";
interface ChatBotOptionSelectionModalProps {
  isOpen: boolean;
  selectedOption: ChatBotOption;
  onClose: () => void;
}

const CHAT_BOT_WHATSAPP_PHONE_NUMBER = getConfigurationVariable(
  "VITE_APP_UNU_HEALTH_SUPPORT_CHAT_BOT_PHONE_NUMBER_ZA"
);

export default function ChatBotOptionSelectionModal({
  isOpen = true,
  selectedOption,
  onClose = () => {},
}: ChatBotOptionSelectionModalProps) {
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] =
    useState<ChatBotLanguageOption | null>(null);

  function onChatBotLanguageOptionButtonClick(option: ChatBotLanguageOption) {
    setSelectedLanguage(option);
  }

  function getPrefilledMessage() {
    if (selectedOption && selectedLanguage) {
      const formattedOption = convertSnakeCaseToSentenceCase(selectedOption);
      return `${formattedOption} ${selectedLanguage}`;
    }
  }

  function onChatOnWhatsappButtonClick() {
    trackEvent({
      event: "action.sweetLifeChatOnWhatsAppButtonClicked",
      source: "Chat bot screen",
      option: selectedOption,
      language: selectedLanguage,
    });

    submitHealthRecordEvent(
      SubmitHealthRecordEventType.SWEETLIFE,
      selectedOption as any
    ).catch(() => {});

    onClose();
  }

  function onDialogClose() {
    setSelectedLanguage(null);
    onClose();
  }
  return (
    <SlideUpDialog
      isOpen={isOpen}
      onClose={onDialogClose}
      label={t("ChatBotPage.modal.language.title")}
    >
      <Stack spacing={4} textAlign={"center"}>
        <Stack spacing={2}>
          <Typography variant="body1" fontWeight={600}>
            {t("ChatBotPage.modal.language.title")}
          </Typography>
          <ToggleButtonGroup
            color="neutral"
            onChange={(_event, option) =>
              onChatBotLanguageOptionButtonClick(option)
            }
            value={selectedLanguage}
            fullWidth
            exclusive
          >
            <ToggleButton value={ChatBotLanguageOption.ENGLISH}>
              {t("ChatBotPage.modal.options.englishButton")}
            </ToggleButton>
            <ToggleButton value={ChatBotLanguageOption.ZULU}>
              {t("ChatBotPage.modal.options.zuluButton")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Button
          component="a"
          href={buildWhatsAppUniversalLinkURL(
            CHAT_BOT_WHATSAPP_PHONE_NUMBER,
            getPrefilledMessage()
          )}
          sx={{ alignItems: "center" }}
          aria-label={t("common.chatOnWhatsApp")}
          color="whatsApp"
          onClick={onChatOnWhatsappButtonClick}
          disabled={!selectedLanguage}
        >
          <Box display="flex" alignSelf="center" pr={1}>
            <IconLoader
              sx={{ fontSize: convertPxToRem(30) }}
              icon="WhatsAppIcon"
            />
          </Box>
          {t("common.chatOnWhatsApp")}
        </Button>
        <Typography color="neutral.light" variant="body1" fontWeight={600}>
          {t("ChatBotPage.modal.footer")}
        </Typography>
      </Stack>
    </SlideUpDialog>
  );
}
