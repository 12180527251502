import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog from "../DefaultDialog";
import {
  trackEvent,
  trackUserInteraction,
} from "../../services/analytics-adapter";
import { Link, useLocation, useNavigate } from "react-router-dom";
import theme, { overrideDefaultLinkStyling } from "../../theme";
import IconLoader from "../IconLoader";
import WatermarkIcon from "../WatermarkIcon";
import { buildWhatsAppUniversalLinkURL, convertPxToRem } from "../../utils";
import FaceWithIcon from "../FaceWithIcon";
import { useGlobalStore } from "../../store";
import { getConfigurationVariable } from "../../services/configuration-adapter";
import { useState } from "react";
import { MedicalAssistance } from "../MedicalAssistance";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "../../services/feature-toggle-adapter";

interface GetHelpModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export default function GetHelpModal({
  onClose,
  isOpen = true,
}: GetHelpModalProps) {
  const SUPPORT_WHATSAPP_PHONE_NUMBER_ZA = getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_SUPPORT_WHATSAPP_PHONE_NUMBER_ZA"
  );
  const SUPPORT_VOICE_PHONE_NUMBER_ZA = getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_SUPPORT_VOICE_PHONE_NUMBER_ZA"
  );

  const SUPPORT_URI = getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_SUPPORT_URI"
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useGlobalStore();

  const isUserLoggedIn = state?.isUserLoggedIn || false;
  const eventSource = location.state?.eventSource;

  const [
    shouldShowGetMedicalAssistanceModal,
    setShouldShowMedicalAssistanceModal,
  ] = useState(false);

  function onModalClose() {
    if (onClose) {
      onClose();
    } else {
      navigate(-1);
    }
  }

  function onChatForMedicalAssistanceLinkClick(event: any) {
    event.preventDefault();
    trackEvent({
      event: "action.getMedicalAssistanceInitiated",
      "getMedicalAssistance.source": "get help modal",
    });

    trackUserInteraction({
      linkText: "Get help modal | getMedicalAssistanceInitiated",
      linkIntent: "navigational",
      linkScope: "button",
    });

    setShouldShowMedicalAssistanceModal(true);
  }

  function onStartMedicalConsultationLinkClick(event: any) {
    event.preventDefault();
    trackEvent({
      event: "action.startMedicalConsultationInitiated",
      "getMedicalAssistance.source": "get help modal",
    });

    trackUserInteraction({
      linkText: "Get help modal | startMedicalConsultationInitiated",
      linkIntent: "navigational",
      linkScope: "button",
    });

    if (state.isThirdPartyMember) {
      navigate("/home/get-medical-assistance/third-party");
    } else {
      navigate("/home/get-medical-assistance/intercare");
    }
  }

  function onLogQueryClick(event: any) {
    if (isUserLoggedIn) {
      trackEvent({
        event: " action.GetSupport",
      });
    }

    trackUserInteraction({
      linkText: "Get support | Get help modal",
      linkIntent: "navigational",
      linkScope: "button",
    });

    if (
      isFeatureEnabled(FeatureToggleIdentifier.ENABLE_GET_HELP_LOG_QUERY_FORM)
    ) {
      event.preventDefault();
      return navigate("/get-help/log-query", {
        state: { eventSource },
      });
    } else {
      if (isUserLoggedIn) {
        event.preventDefault();
        return navigate("/home/more/get-support", {
          state: { eventSource },
        });
      }
    }
  }

  return (
    <>
      {shouldShowGetMedicalAssistanceModal && (
        <MedicalAssistance showInformationPrompt />
      )}

      {!shouldShowGetMedicalAssistanceModal && (
        <DefaultDialog
          isOpen={isOpen}
          onClose={onModalClose}
          paperStyleProps={{
            minWidth: convertPxToRem(320),
            px: 2,
          }}
          label={t("GetHelpModal.label")}
          actionButtons={
            <>
              {isUserLoggedIn ? (
                <>
                  <Stack alignItems={"center"} spacing={1.5}>
                    <IconLoader
                      icon="ChatHeartIcon"
                      color="error"
                      sx={{
                        fontSize: convertPxToRem(56),
                        alignSelf: "center",
                      }}
                    />
                    <Typography variant="h3" fontWeight={600} align="center">
                      {t("GetHelpModal.getHelpTitle")}
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    {state.currentUser.isRetailMember === true ||
                    state.isThirdPartyMember ? (
                      <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        component={Link}
                        to=""
                        onClick={onStartMedicalConsultationLinkClick}
                        sx={{
                          ...overrideDefaultLinkStyling,
                          color: theme.palette.neutral.dark,
                        }}
                      >
                        <Stack flexDirection="row" alignItems="center">
                          <WatermarkIcon
                            height={35}
                            width={35}
                            iconSize="medium"
                            color={theme.palette.primary[300]}
                            foreground={theme.palette.primary.main}
                            Icon={"StethoscopeIcon"}
                            extra={{
                              marginRight: 1.5,
                            }}
                          />
                          <Typography variant="h4">
                            {t("GetHelpModal.startConsultationLabel")}
                          </Typography>
                        </Stack>
                        <IconLoader
                          icon="ChevronNextIcon"
                          sx={{ color: theme.palette.neutral.light }}
                        />
                      </Stack>
                    ) : (
                      <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        component={Link}
                        to=""
                        onClick={onChatForMedicalAssistanceLinkClick}
                        sx={{
                          ...overrideDefaultLinkStyling,
                          color: theme.palette.neutral.dark,
                        }}
                      >
                        <Stack flexDirection="row" alignItems="center">
                          <WatermarkIcon
                            height={35}
                            width={35}
                            iconSize="medium"
                            color={theme.palette.primary[300]}
                            foreground={theme.palette.primary.main}
                            Icon={"WhatsAppIcon"}
                            extra={{
                              marginRight: 1.5,
                            }}
                          />
                          <Typography variant="h4">
                            {t("GetHelpModal.medicalAssistanceLabel")}
                          </Typography>
                        </Stack>
                        <IconLoader
                          icon="ChevronNextIcon"
                          sx={{ color: theme.palette.neutral.light }}
                        />
                      </Stack>
                    )}
                    <Divider />
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      onClick={onLogQueryClick}
                      href={SUPPORT_URI}
                      target="_blank"
                      component="a"
                      sx={{
                        ...overrideDefaultLinkStyling,
                        color: theme.palette.neutral.dark,
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <WatermarkIcon
                          height={35}
                          width={35}
                          iconSize="medium"
                          color={theme.palette.primary[300]}
                          foreground={theme.palette.primary.main}
                          Icon={"HandWithHeartIcon"}
                          extra={{
                            marginRight: 1.5,
                          }}
                        />
                        <Stack>
                          <Typography variant="h4">
                            {t("GetHelpModal.getSupportLabel")}
                          </Typography>
                        </Stack>
                      </Stack>
                      <IconLoader
                        icon="ChevronNextIcon"
                        sx={{ color: theme.palette.neutral.light }}
                      />
                    </Stack>
                  </Stack>
                </>
              ) : (
                <>
                  <Stack alignItems={"center"} spacing={1.5}>
                    <FaceWithIcon
                      color={"accent1.main"}
                      height={56}
                      width={56}
                      Icon={"PhoneIcon"}
                    />
                    <Typography variant="h3" fontWeight={600} align="center">
                      {t("GetHelpModal.contactSupportTitle")}
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      component={Link}
                      target="_blank"
                      to={buildWhatsAppUniversalLinkURL(
                        SUPPORT_WHATSAPP_PHONE_NUMBER_ZA
                      )}
                      sx={{
                        ...overrideDefaultLinkStyling,
                        color: theme.palette.neutral.dark,
                      }}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <WatermarkIcon
                          height={35}
                          width={35}
                          iconSize="medium"
                          color={theme.palette.primary[300]}
                          foreground={theme.palette.primary.main}
                          Icon={"WhatsAppIcon"}
                          extra={{
                            marginRight: 1.5,
                          }}
                        />
                        <Typography variant="h4">
                          {t("GetHelpModal.whatsAppChat")}
                        </Typography>
                      </Stack>
                      <IconLoader
                        icon="ChevronNextIcon"
                        sx={{ color: theme.palette.neutral.light }}
                      />
                    </Stack>

                    <Divider />

                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      component={Link}
                      to={`tel:${SUPPORT_VOICE_PHONE_NUMBER_ZA}`}
                      sx={{
                        ...overrideDefaultLinkStyling,
                        color: theme.palette.neutral.dark,
                      }}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <WatermarkIcon
                          height={35}
                          width={35}
                          iconSize="medium"
                          color={theme.palette.primary[300]}
                          foreground={theme.palette.primary.main}
                          Icon={"PhoneIcon"}
                          extra={{
                            marginRight: 1.5,
                          }}
                        />
                        <Stack flexDirection="column">
                          <Typography variant="h4">
                            {t("GetHelpModal.callUs.title", {
                              phoneNumber: SUPPORT_VOICE_PHONE_NUMBER_ZA,
                            })}
                          </Typography>
                          <Typography variant="body2">
                            {t("GetHelpModal.callUs.availability")}
                          </Typography>
                        </Stack>
                      </Stack>
                      <IconLoader
                        icon="ChevronNextIcon"
                        sx={{ color: theme.palette.neutral.light }}
                      />
                    </Stack>

                    <Divider />

                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      onClick={onLogQueryClick}
                      component="a"
                      href={SUPPORT_URI}
                      target="_blank"
                      sx={{
                        ...overrideDefaultLinkStyling,
                        color: theme.palette.neutral.dark,
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <WatermarkIcon
                          height={35}
                          width={35}
                          iconSize="medium"
                          color={theme.palette.primary[300]}
                          foreground={theme.palette.primary.main}
                          Icon={"QuestionMarkIcon"}
                          extra={{
                            marginRight: 1.5,
                          }}
                        />
                        <Typography variant="h4">
                          {t("GetHelpModal.logQueryLabel")}
                        </Typography>
                      </Stack>
                      <IconLoader
                        icon="ChevronNextIcon"
                        sx={{ color: theme.palette.neutral.light }}
                      />
                    </Stack>
                  </Stack>
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
}
