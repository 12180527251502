import { getHealthRecord, SortDirection } from "@/services/core-api-adapter";
import { Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HealthRecordEmptyState from "@/components/HealthRecordEmptyState";
import HealthRecordList from "@/components/HealthRecordList";
import HealthRecordPlaceholder from "@/components/HealthRecordPlaceholder";
import LoadingSpinner from "@/components/LoadingSpinner";

export default function HealthRecordContainer() {
  const [isLoading, setIsLoading] = useState(true);
  const [healthRecordData, setHealthRecordData] = useState<any>(null);
  const [sortDirection] = useState<SortDirection>("Desc");

  const [isError, setIsError] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();

    setIsLoading(true);
    setIsError(false);
    getHealthRecord(sortDirection, { signal: controller.signal })
      .then((result) => {
        setHealthRecordData(result);
        setIsLoading(false);
      })
      .catch((err) => {
        if (controller.signal.aborted === false) {
          setIsError(err);
          setIsLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  const hasEvents = healthRecordData?.events?.length > 0 || false;
  const noEvents = healthRecordData?.events?.length === 0 || false;
  const isLoadedWithoutError = !isLoading && !isError;

  return (
    <>
      {isLoading && (
        <Stack alignItems="center">
          <LoadingSpinner />
        </Stack>
      )}

      {isLoadedWithoutError && hasEvents && (
        <Stack spacing={2}>
          <Typography variant="h3">{t("HealthRecordList.title")}</Typography>
          <HealthRecordList data={healthRecordData} />
        </Stack>
      )}

      {isLoadedWithoutError && noEvents && <HealthRecordEmptyState />}

      {!isLoading && isError && <HealthRecordPlaceholder />}
    </>
  );
}
