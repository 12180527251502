import {
  PathologyPanel,
  PathologyPanelOption,
  Status,
} from "@/services/core-api-adapter";
import theme, { defaultFullscreenPageStyling } from "@/theme";
import { Stack, Typography } from "@mui/material";
import CircledBackButton from "../CircledBackButton";
import { Trans, useTranslation } from "react-i18next";
import { convertPxToRem } from "@/utils";
import ItemPill from "../ItemPill";
import IconLoader from "../IconLoader";
import ButtonWithAnalytics from "../ButtonWithAnalytics";

const iconStyles = {
  width: convertPxToRem(36),
  height: convertPxToRem(36),
};

const pathologyPanels: PathologyPanel[] = [
  {
    id: "ee15c3f0-a2fc-4c9c-8a80-2c60fcf77823",
    name: PathologyPanelOption.ANAEMIA,
    friendlyName: "ANAEMIA",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "d24afc9c-03b2-474c-b710-0b46e57b4bc1",
    name: PathologyPanelOption.HIV_SCREENING,
    friendlyName: "HIV_SCREENING",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "f5bc7cfd-9283-4033-bcd1-842dae372530",
    name: PathologyPanelOption.MALE_UNDER_45,
    friendlyName: "MALE_UNDER_45",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "dae0c139-2fac-44a0-bdff-a8f3b0624d36",
    name: PathologyPanelOption.MALE_OVER_45,
    friendlyName: "MALE_OVER_45",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "7697221a-5e92-4a13-8a77-40df6cf5a9a7",
    name: PathologyPanelOption.HEPATITIS_B,
    friendlyName: "HEPATITIS_B",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "896c7a91-5ef5-49fe-b3f4-4eacaa91b206",
    name: PathologyPanelOption.STD_SCREENING_EXCL_HIV,
    friendlyName: "STD_SCREENING_EXCL_HIV",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "fa2dfc8f-8727-446f-a063-556d16e737db",
    name: PathologyPanelOption.STD_SCREENING_INCL_HIV,
    friendlyName: "STD_SCREENING_INCL_HIV",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: PathologyPanelOption.FEMALE,
    friendlyName: "FEMALE",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: PathologyPanelOption.HIV_MONITORING,
    friendlyName: "HIV_MONITORING",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: PathologyPanelOption.STD_PCR,
    friendlyName: "STD_PCR",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: PathologyPanelOption.SYPHILIS,
    friendlyName: "SYPHILIS",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
];

const pathologyPanelPillMap: { [key: string]: any } = {
  MALE_UNDER_45: {
    title: "PathologyPanel.maleUnder45.title",
    subtitle: "PathologyPanel.maleUnder45.subtitle",
    icon: <IconLoader icon="MaleSexIcon" sx={iconStyles} />,
  },
  MALE_OVER_45: {
    title: "PathologyPanel.maleOver45.title",
    subtitle: "PathologyPanel.maleOver45.subtitle",
    icon: <IconLoader icon="MaleSexIcon" sx={iconStyles} />,
  },
  FEMALE: {
    title: "PathologyPanel.female.title",
    subtitle: "PathologyPanel.female.subtitle",
    icon: <IconLoader icon="FemaleSexIcon" sx={iconStyles} />,
  },
  HIV_SCREENING: {
    title: "PathologyPanel.hivScreening.title",
    subtitle: "PathologyPanel.hivScreening.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  HIV_MONITORING: {
    title: "PathologyPanel.hivMonitoring.title",
    subtitle: "PathologyPanel.hivMonitoring.subtitle",
    icon: <IconLoader icon="VomitingAndDiarrhoeaIcon" sx={iconStyles} />,
  },
  ANAEMIA: {
    title: "PathologyPanel.anaemia.title",
    subtitle: "PathologyPanel.anaemia.subtitle",
    icon: <IconLoader icon="DropIcon" sx={iconStyles} />,
  },
  STD_SCREENING_INCL_HIV: {
    title: "PathologyPanel.stdScreeningInclHiv.title",
    subtitle: "PathologyPanel.stdScreeningInclHiv.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  STD_SCREENING_EXCL_HIV: {
    title: "PathologyPanel.stdScreeningExlHiv.title",
    subtitle: "PathologyPanel.stdScreeningExlHiv.subtitle",
    icon: <IconLoader icon="ContraceptionIcon" sx={iconStyles} />,
  },
  SYPHILIS: {
    title: "PathologyPanel.syphilis.title",
    subtitle: "PathologyPanel.syphilis.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  HEPATITIS_B: {
    title: "PathologyPanel.hepatitisB.title",
    subtitle: "PathologyPanel.hepatitisB.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  STD_PCR: {
    title: "PathologyPanel.stdPcr.title",
    subtitle: "PathologyPanel.stdPcr.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
};

export default function PathologyPanels() {
  const { t } = useTranslation();
  return (
    <Stack spacing={3} sx={{ ...defaultFullscreenPageStyling }}>
      <CircledBackButton showLabel={true} />
      <h1>{t("PathologyPanel.page.title")}</h1>
      <Stack spacing={2}>
        {pathologyPanels.map((pathologyPanel) => (
          <ItemPill
            key={pathologyPanel.id}
            onClick={() => console.log("onClick")}
            selectedItem={pathologyPanel}
            itemMap={pathologyPanelPillMap}
            titleStyles={{ fontSize: convertPxToRem(16), fontWeight: 600 }}
            shouldShowPrice
          />
        ))}
        <Stack
          spacing={2}
          sx={{
            backgroundColor: theme.palette.primary[100],
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Stack flexDirection="row" gap={1}>
            <IconLoader icon="ChatBubblePlusIcon" />
            <Stack>
              <Typography variant="h4">
                {t("PathologyPanel.page.doctorConsultCTA.title")}
              </Typography>
              <Typography variant="h4" fontWeight="400">
                <Trans>
                  {t("PathologyPanel.page.doctorConsultCTA.subtitle")}
                </Trans>
              </Typography>
            </Stack>
          </Stack>
          <ButtonWithAnalytics
            page="PathologyPanel"
            text={t("PathologyPanel.page.doctorConsultCTA.button")}
            intent="navigational"
            variant="outlined"
            color="neutral"
            onClick={() => console.log("onClick")}
          >
            {t("PathologyPanel.page.doctorConsultCTA.button")}
          </ButtonWithAnalytics>
        </Stack>
      </Stack>
    </Stack>
  );
}
