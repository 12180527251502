import React, { useState, useId, useEffect } from "react";
import { Stack, Typography, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import InternationalPhoneNumberInput, {
  InternationalPhoneNumber,
} from "../InternationalPhoneNumberInput";
import { GetHelpFABConfig, styleInputDefault } from "../../theme";
import GetHelpCTAButton from "../GetHelpCTAButton";
import {
  AnalyticsEvent,
  trackFormEvent,
} from "../../services/analytics-adapter";
import { convertPxToRem } from "../../utils";
import ButtonWithAnalytics from "../ButtonWithAnalytics";

interface CollectThirdPartyUserDetailsProps {
  onSubmit: (userData: {
    phoneNumber: InternationalPhoneNumber;
    firstName: string;
    lastName: string;
  }) => void;
  onLoginClick: () => void;
  value?: { globalSubscriberNumber: string; countryCode: string };
  existingUserDetails?: {
    firstName: string;
    lastName: string;
    phoneNumber: InternationalPhoneNumber;
  };
}

export default function CollectThirdPartyUserDetails(
  props: CollectThirdPartyUserDetailsProps
) {
  const { t } = useTranslation();
  const inputID = useId();
  const firstNameInputID = useId();
  const lastNameInputID = useId();

  const defaultCountryCode = "UG";
  const [phoneNumber, setPhoneNumber] = useState<InternationalPhoneNumber>({
    countryCode: defaultCountryCode,
    globalSubscriberNumber:
      props.existingUserDetails?.phoneNumber?.globalSubscriberNumber || "",
  });
  const [firstNameValue, setFirstNameValue] = useState<string>(
    props.existingUserDetails?.firstName || ""
  );
  const [lastNameValue, setLastNameValue] = useState<string>(
    props.existingUserDetails?.lastName || ""
  );

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Enter cellphone number",
    });
  }, []);

  function onFirstNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setFirstNameValue(value);
  }

  function onLastNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setLastNameValue(value);
  }

  function onChange(phoneNumber: InternationalPhoneNumber, isValid: boolean) {
    setIsSubmitButtonEnabled(isValid);
    setPhoneNumber(phoneNumber);
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onSubmit({
      phoneNumber,
      firstName: firstNameValue,
      lastName: lastNameValue,
    });
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 2 }}>
          <FaceIconBrandLarge />
          <Stack spacing={2}>
            <Typography component="h2" variant="h3">
              {t("CollectThirdPartyUserDetails.title")}
            </Typography>
          </Stack>
          <Stack pb={4}>
            <Typography variant="body1">
              {t("CollectThirdPartyUserDetails.subtitle")}
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={firstNameInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("FirstNameInput.label")}
              </Typography>
            </InputLabel>
            <TextField
              id={firstNameInputID}
              value={firstNameValue}
              onInput={onFirstNameInput}
              size="medium"
              inputProps={{
                autoComplete: "off",
                "aria-label": t("FirstNameInput.label"),
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor={lastNameInputID}>
              <Typography color="neutral.700" variant="body2" fontWeight={300}>
                {t("LastNameInput.label")}
              </Typography>
            </InputLabel>
            <TextField
              id={lastNameInputID}
              value={lastNameValue}
              size="medium"
              onInput={onLastNameInput}
              inputProps={{
                autoComplete: "off",
                "aria-label": t("LastNameInput.label"),
              }}
              sx={{
                input: {
                  ...styleInputDefault,
                  fontSize: convertPxToRem(16),
                },
              }}
              fullWidth
            />
          </Stack>

          <InputLabel htmlFor={inputID}>
            {t("CollectPhoneNumber.CellphoneNumberInputLabel")}
          </InputLabel>
          <InternationalPhoneNumberInput
            value={phoneNumber}
            isDialingCodeDropDownDisabled={true}
            onChange={onChange}
          />
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
              py={1}
            >
              <GetHelpCTAButton eventName="action.contactSupportFab" />
            </Stack>

            <ButtonWithAnalytics
              page="COUSignup"
              intent="navigational"
              text={t("common.nextButton")}
              type="submit"
              color="primary"
              disabled={!isSubmitButtonEnabled}
              aria-label={t("common.nextButton")}
              fullWidth
            >
              {t("common.nextButton")}
            </ButtonWithAnalytics>
          </Stack>
        </Stack>
      </form>
      <ButtonWithAnalytics
        page="COUSignup"
        intent="navigational"
        text={t("CollectThirdPartyUserDetails.loginButton.label")}
        color="primary"
        variant="text"
        onClick={props.onLoginClick}
      >
        {t("CollectThirdPartyUserDetails.loginButton.label")}
      </ButtonWithAnalytics>
    </>
  );
}
