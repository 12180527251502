import { useTranslation } from "react-i18next";
import {
  findMainMemberInMembership,
  findMemberByID,
  getMembershipMedicalAssistanceServiceWhatsAppChannel,
  Member,
  submitHealthRecordEvent,
  SubmitHealthRecordEventType,
} from "../../services/core-api-adapter";
import { Box, Button, Stack, Typography } from "@mui/material";
import { trackEvent } from "../../services/analytics-adapter";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import WatermarkIcon from "../WatermarkIcon";
import { useEffect, useState } from "react";
import useGetMemberships from "../../hooks/useGetMemberships";
import MedicalAssistanceMembersDropdown from "../MedicalAssistanceMembersDropdown";
import IconLoader from "../IconLoader";
import DefaultError from "../DefaultError";
import { useNavigate } from "react-router";
import { useGlobalStore } from "../../store";
import theme from "../../theme";
import { buildWhatsAppUniversalLinkURL } from "../../utils";

export default function ChatWithANurse() {
  const { t } = useTranslation();

  const {
    firstMembership,
    memberships,
    isMembershipsLoading,
    membershipsFetchError,
  } = useGetMemberships();

  const [selectedMemberMembershipID, setSelectedMemberMembershipID] = useState<
    string | null
  >(null);

  const { state } = useGlobalStore();

  const [mainMember, setMainMember] = useState<Member | null>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [shouldShowMembersDropdown, setShouldShowMembersDropdown] = useState<
    boolean | null
  >(null);
  const [submitWhatsAppChatEventError, setSubmitWhatsAppChatEventError] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldShowMembersDropdown === false) {
      setSelectedMemberMembershipID(
        findMainMemberInMembership(firstMembership, state.currentUser.memberId)
          ?.membershipDetails.id || null
      );
    }

    if (isMembershipsLoading === false && membershipsFetchError === false) {
      setShouldShowMembersDropdown(firstMembership.members.length > 1);
      setMainMember(
        findMainMemberInMembership(firstMembership, state.currentUser.memberId)
      );
    }

    setPhoneNumber(
      getMembershipMedicalAssistanceServiceWhatsAppChannel(firstMembership)
        ?.value || ""
    );
  }, [
    memberships,
    shouldShowMembersDropdown,
    isMembershipsLoading,
    membershipsFetchError,
  ]);

  useEffect(() => {
    setMainMember(findMemberByID(firstMembership, selectedMemberMembershipID));
  }, [selectedMemberMembershipID, memberships]);

  function buildWhatsAppURL() {
    const prefilledMessage = selectedMemberMembershipID
      ? t("common.whatsAppPrefills.chatOnBehalfOf", {
          personFullName: mainMember?.memberDetails?.memberFullName || "",
          identificationType:
            mainMember?.memberDetails?.identityDocumentType || "",
          identificationValue:
            mainMember?.memberDetails?.identityDocumentValue || "",
        })
      : "";

    return buildWhatsAppUniversalLinkURL(phoneNumber, prefilledMessage);
  }

  function onChatOnWhatsAppClick() {
    trackEvent({
      event: "action.getMedicalAssistanceRequested",
      "getMedicalAssistance.channel": "chat on whatsapp",
    });

    submitHealthRecordEvent(SubmitHealthRecordEventType.NURSE).catch(() => {
      setSubmitWhatsAppChatEventError(true);
    });
  }

  function handleSetSelectedMemberMembershipID(
    selectedMemberMembershipID: any
  ) {
    setSelectedMemberMembershipID(selectedMemberMembershipID);
  }

  function onComeBackLater() {
    navigate("/home");
  }

  function onTryAgain() {
    navigate(-1);
  }

  return (
    <>
      {submitWhatsAppChatEventError ? (
        <DefaultError onComeBackLater={onComeBackLater} onTryAgain={onTryAgain}>
          <Typography variant="h2">{t("GenericError.title")}</Typography>
          <Typography pb={2}>{t("GenericError.content")}</Typography>
        </DefaultError>
      ) : (
        <Stack sx={{ p: 2 }} justifyContent="space-between" spacing={2}>
          <FaceIconBrandLarge />

          {shouldShowMembersDropdown === false && mainMember !== null && (
            <Stack spacing={1}>
              <Typography variant="h3">
                {t("ChatWithANurse.heading", {
                  name: mainMember.memberDetails.memberFullName.split(" ")[0],
                })}
              </Typography>
            </Stack>
          )}

          {shouldShowMembersDropdown === true && (
            <MedicalAssistanceMembersDropdown
              setSelectedMembershipId={handleSetSelectedMemberMembershipID}
              memberships={memberships}
              selectMemberLabel={t("ChatWithANurse.selectMemberLabel")}
              selectMemberPlaceholder={t(
                "ChatWithANurse.selectMemberPlaceholder"
              )}
            />
          )}

          <Stack spacing={1}>
            <Stack direction="row" alignItems="center">
              <Box sx={{ position: "relative", height: "75px" }}>
                <WatermarkIcon
                  height={42}
                  width={42}
                  color={theme.palette.accent2.light}
                  foreground="primary"
                  Icon={"WhatsAppIcon"}
                />
                <WatermarkIcon
                  height={42}
                  width={42}
                  color={theme.palette.accent2.light}
                  extra={{ position: "absolute", bottom: 0, left: 0 }}
                  foreground="primary"
                  Icon={"VideoIcon"}
                />
              </Box>
              <Typography variant="body1" marginLeft={1.5}>
                {t("ChatWithANurse.point1")}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center">
              <WatermarkIcon
                height={42}
                width={42}
                color={theme.palette.accent2.light}
                foreground="primary"
                Icon={"PillBottleIcon"}
              />
              <Typography variant="body1" marginLeft={1.5}>
                {t("ChatWithANurse.point2")}
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={1} justifyContent="center" textAlign={"center"}>
            <Typography fontWeight={600} variant="h3">
              {t("ChatWithANurse.whatsAppTitle")}
            </Typography>

            <Typography fontWeight={600}>
              {t("ChatWithANurse.whatsAppSubtitle")}
            </Typography>
          </Stack>

          <Button
            component="a"
            href={buildWhatsAppURL()}
            target="_blank"
            onClick={onChatOnWhatsAppClick}
            color="whatsApp"
            startIcon={<IconLoader icon="ChatBubbleSpeechIcon" />}
            disabled={!selectedMemberMembershipID}
          >
            {t("common.chatOnWhatsApp")}
          </Button>

          <Stack spacing={1} justifyContent="center" textAlign={"center"}>
            <Typography variant="body2">
              {t("ChatWithANurse.whatsAppCallTitle")}
            </Typography>

            <Typography fontWeight={600}>{phoneNumber || "..."}</Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}
