import { Stack, Typography } from "@mui/material";
import FullscreenBackground from "../FullscreenBackground";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../utils";
import { logout } from "../../services/core-api-adapter";
import { useGlobalStore } from "../../store";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ButtonWithAnalytics from "../ButtonWithAnalytics";

export default function MembershipManagementChangeRequestSuccess() {
  const { t } = useTranslation();
  const { dispatch } = useGlobalStore();
  const navigate = useNavigate();

  function onLogoutButtonClick() {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
      navigate("/");
    });
  }

  function onGoHomeButtonClick() {
    navigate("/");
  }

  return (
    <Stack
      justifyContent="flex-end"
      color="primary.contrastText"
      textAlign="center"
      p={2}
      sx={{
        height: "100%",
      }}
    >
      <Stack spacing={4} py={8}>
        <IconLoader
          icon="RingingPhoneIcon"
          color="primary"
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />
        <Typography color="inherit" variant="h1">
          {t("MembershipManagementChangeRequestSuccess.title")}
        </Typography>
        <Typography color="inherit" variant="body1">
          {t("MembershipManagementChangeRequestSuccess.subtitle")}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={2}>
        <ButtonWithAnalytics
          page="Change plan request received"
          text={t("common.goHomeButton")}
          intent="navigational"
          color="info"
          onClick={onGoHomeButtonClick}
          variant="outlined"
          fullWidth
        >
          {t("common.goHomeButton")}
        </ButtonWithAnalytics>
        <ButtonWithAnalytics
          page="Change plan request received"
          text={t("common.logOutButton")}
          intent="navigational"
          color="info"
          onClick={onLogoutButtonClick}
          fullWidth
        >
          {t("common.logOutButton")}
        </ButtonWithAnalytics>
      </Stack>

      <FullscreenBackground color="primary.main" />
    </Stack>
  );
}
