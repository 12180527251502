import { Divider, Grid, Stack, Typography } from "@mui/material";
import ItemPill from ".";
import {
  DirectToDoctorPrimarySymptom,
  DirectToDoctorPrimarySymptomOption,
  Status,
  PathologyPanel,
  PathologyPanelOption,
} from "@/services/core-api-adapter";
import { convertPxToRem } from "@/utils";
import IconLoader from "../IconLoader";

const primarySymptoms: DirectToDoctorPrimarySymptom[] = [
  {
    id: "ee15c3f0-a2fc-4c9c-8a80-2c60fcf77823",
    name: DirectToDoctorPrimarySymptomOption.SKIN_CONDITIONS,
    friendlyName: "SKIN_CONDITIONS",
    status: Status.ACTIVE,
  },
  {
    id: "d24afc9c-03b2-474c-b710-0b46e57b4bc1",
    name: DirectToDoctorPrimarySymptomOption.EYE_INFECTIONS,
    friendlyName: "EYE_INFECTIONS",
    status: Status.ACTIVE,
  },
  {
    id: "f5bc7cfd-9283-4033-bcd1-842dae372530",
    name: DirectToDoctorPrimarySymptomOption.BLADDER_INFECTION,
    friendlyName: "BLADDER_INFECTION",
    status: Status.ACTIVE,
  },
  {
    id: "dae0c139-2fac-44a0-bdff-a8f3b0624d36",
    name: DirectToDoctorPrimarySymptomOption.COLD_FLU,
    friendlyName: "COLD_FLU",
    status: Status.ACTIVE,
  },
  {
    id: "7697221a-5e92-4a13-8a77-40df6cf5a9a7",
    name: DirectToDoctorPrimarySymptomOption.VOMITING_DIARRHEA,
    friendlyName: "VOMITING_DIARRHEA",
    status: Status.ACTIVE,
  },
  {
    id: "896c7a91-5ef5-49fe-b3f4-4eacaa91b206",
    name: DirectToDoctorPrimarySymptomOption.HEADACHE,
    friendlyName: "HEADACHE",
    status: Status.ACTIVE,
  },
  {
    id: "fa2dfc8f-8727-446f-a063-556d16e737db",
    name: DirectToDoctorPrimarySymptomOption.BACK_PAIN,
    friendlyName: "BACK_PAIN",
    status: Status.ACTIVE,
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: DirectToDoctorPrimarySymptomOption.CONTRACEPTION,
    friendlyName: "CONTRACEPTION",
    status: Status.ACTIVE,
  },
  {
    id: "ecd372e4-2a23-44e4-a4e2-2ee556cc8020",
    name: DirectToDoctorPrimarySymptomOption.GENITAL_INFECTIONS,
    friendlyName: "GENITAL_INFECTIONS",
    status: Status.ACTIVE,
  },
  {
    id: "4b00a459-72e5-4c7e-bd46-3d63e129078b",
    name: DirectToDoctorPrimarySymptomOption.GENERAL_CONDITIONS,
    friendlyName: "GENERAL_CONDITIONS",
    status: Status.ACTIVE,
  },
];

const pathologyPanels: PathologyPanel[] = [
  {
    id: "ee15c3f0-a2fc-4c9c-8a80-2c60fcf77823",
    name: PathologyPanelOption.ANAEMIA,
    friendlyName: "ANAEMIA",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "d24afc9c-03b2-474c-b710-0b46e57b4bc1",
    name: PathologyPanelOption.HIV_SCREENING,
    friendlyName: "HIV_SCREENING",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "f5bc7cfd-9283-4033-bcd1-842dae372530",
    name: PathologyPanelOption.MALE_UNDER_45,
    friendlyName: "MALE_UNDER_45",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "dae0c139-2fac-44a0-bdff-a8f3b0624d36",
    name: PathologyPanelOption.MALE_OVER_45,
    friendlyName: "MALE_OVER_45",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "7697221a-5e92-4a13-8a77-40df6cf5a9a7",
    name: PathologyPanelOption.HEPATITIS_B,
    friendlyName: "HEPATITIS_B",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "896c7a91-5ef5-49fe-b3f4-4eacaa91b206",
    name: PathologyPanelOption.STD_SCREENING_EXCL_HIV,
    friendlyName: "STD_SCREENING_EXCL_HIV",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "fa2dfc8f-8727-446f-a063-556d16e737db",
    name: PathologyPanelOption.STD_SCREENING_INCL_HIV,
    friendlyName: "STD_SCREENING_INCL_HIV",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: PathologyPanelOption.FEMALE,
    friendlyName: "FEMALE",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: PathologyPanelOption.HIV_MONITORING,
    friendlyName: "HIV_MONITORING",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: PathologyPanelOption.STD_PCR,
    friendlyName: "STD_PCR",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
  {
    id: "554bb6f0-88b1-45d2-ba84-f418c57c5bba",
    name: PathologyPanelOption.SYPHILIS,
    friendlyName: "SYPHILIS",
    status: Status.ACTIVE,
    amount: "R100",
    chargeCode: "ABC",
    BloodTestType: "FBC",
  },
];

interface dataMap {
  [key: string]: any;
}

const iconStyles = {
  width: convertPxToRem(36),
  height: convertPxToRem(36),
};

const primarySymptomPillMap: dataMap = {
  SKIN_CONDITIONS: {
    title: "DirectToDoctor.primarySymptoms.skinConditions.title",
    subtitle: "DirectToDoctor.primarySymptoms.skinConditions.subtitle",
    icon: <IconLoader icon="SkinConditionsIcon" sx={iconStyles} />,
  },
  EYE_INFECTIONS: {
    title: "DirectToDoctor.primarySymptoms.eyeInfection.title",
    subtitle: "DirectToDoctor.primarySymptoms.eyeInfection.subtitle",
    icon: <IconLoader icon="EyeInfectionIcon" sx={iconStyles} />,
  },
  BLADDER_INFECTION: {
    title: "DirectToDoctor.primarySymptoms.bladderInfection.title",
    subtitle: "DirectToDoctor.primarySymptoms.bladderInfection.subtitle",
    icon: <IconLoader icon="BladderInfectionIcon" sx={iconStyles} />,
  },
  COLD_FLU: {
    title: "DirectToDoctor.primarySymptoms.coldFlu.title",
    subtitle: "DirectToDoctor.primarySymptoms.coldFlu.subtitle",
    icon: <IconLoader icon="ColdAndFluIcon" sx={iconStyles} />,
  },
  VOMITING_DIARRHEA: {
    title: "DirectToDoctor.primarySymptoms.vomitingDiarrhoea.title",
    subtitle: "DirectToDoctor.primarySymptoms.vomitingDiarrhoea.subtitle",
    icon: <IconLoader icon="VomitingAndDiarrhoeaIcon" sx={iconStyles} />,
  },
  HEADACHE: {
    title: "DirectToDoctor.primarySymptoms.headache.title",
    subtitle: "DirectToDoctor.primarySymptoms.headache.subtitle",
    icon: <IconLoader icon="HeadacheIcon" sx={iconStyles} />,
  },
  BACK_PAIN: {
    title: "DirectToDoctor.primarySymptoms.backPain.title",
    subtitle: "DirectToDoctor.primarySymptoms.backPain.subtitle",
    icon: <IconLoader icon="BackPainIcon" sx={iconStyles} />,
  },
  CONTRACEPTION: {
    title: "DirectToDoctor.primarySymptoms.contraception.title",
    subtitle: "DirectToDoctor.primarySymptoms.contraception.subtitle",
    icon: <IconLoader icon="ContraceptionIcon" sx={iconStyles} />,
  },
  GENITAL_INFECTIONS: {
    title: "DirectToDoctor.primarySymptoms.genitalInfections.title",
    subtitle: "DirectToDoctor.primarySymptoms.genitalInfections.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  GENERAL_CONDITIONS: {
    title: "DirectToDoctor.primarySymptoms.generalConditions.title",
    subtitle: "DirectToDoctor.primarySymptoms.generalConditions.subtitle",
    icon: <IconLoader icon="GeneralDoctorConsultIcon" sx={iconStyles} />,
  },
};

const pathologyPanelPillMap: dataMap = {
  MALE_UNDER_45: {
    title: "PathologyPanel.maleUnder45.title",
    subtitle: "PathologyPanel.maleUnder45.subtitle",
    icon: <IconLoader icon="MaleSexIcon" sx={iconStyles} />,
  },
  MALE_OVER_45: {
    title: "PathologyPanel.maleOver45.title",
    subtitle: "PathologyPanel.maleOver45.subtitle",
    icon: <IconLoader icon="MaleSexIcon" sx={iconStyles} />,
  },
  FEMALE: {
    title: "PathologyPanel.female.title",
    subtitle: "PathologyPanel.female.subtitle",
    icon: <IconLoader icon="FemaleSexIcon" sx={iconStyles} />,
  },
  HIV_SCREENING: {
    title: "PathologyPanel.hivScreening.title",
    subtitle: "PathologyPanel.hivScreening.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  HIV_MONITORING: {
    title: "PathologyPanel.hivMonitoring.title",
    subtitle: "PathologyPanel.hivMonitoring.subtitle",
    icon: <IconLoader icon="VomitingAndDiarrhoeaIcon" sx={iconStyles} />,
  },
  ANAEMIA: {
    title: "PathologyPanel.anaemia.title",
    subtitle: "PathologyPanel.anaemia.subtitle",
    icon: <IconLoader icon="DropIcon" sx={iconStyles} />,
  },
  STD_SCREENING_INCL_HIV: {
    title: "PathologyPanel.stdScreeningInclHiv.title",
    subtitle: "PathologyPanel.stdScreeningInclHiv.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  STD_SCREENING_EXCL_HIV: {
    title: "PathologyPanel.stdScreeningExlHiv.title",
    subtitle: "PathologyPanel.stdScreeningExlHiv.subtitle",
    icon: <IconLoader icon="ContraceptionIcon" sx={iconStyles} />,
  },
  SYPHILIS: {
    title: "PathologyPanel.syphilis.title",
    subtitle: "PathologyPanel.syphilis.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  HEPATITIS_B: {
    title: "PathologyPanel.hepatitisB.title",
    subtitle: "PathologyPanel.hepatitisB.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
  STD_PCR: {
    title: "PathologyPanel.stdPcr.title",
    subtitle: "PathologyPanel.stdPcr.subtitle",
    icon: <IconLoader icon="SexualTransmittedDiseasesIcon" sx={iconStyles} />,
  },
};

export default function ItemPillExamples() {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="h2">Direct to Doctor</Typography>
        <Grid container spacing={2} rowSpacing={1}>
          {primarySymptoms.map((primarySymptom) => (
            <Grid key={primarySymptom.id} item>
              <ItemPill
                onClick={() => console.log("onClick")}
                selectedItem={primarySymptom}
                itemMap={primarySymptomPillMap}
                titleStyles={{ fontSize: convertPxToRem(16), fontWeight: 600 }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Divider />
      <Stack spacing={1}>
        <Typography variant="h2">Pathology Panels</Typography>
        <Grid container spacing={2} rowSpacing={1}>
          {pathologyPanels.map((pathologyPanel) => (
            <Grid key={pathologyPanel.id} item>
              <ItemPill
                onClick={() => console.log("onClick")}
                selectedItem={pathologyPanel}
                itemMap={pathologyPanelPillMap}
                titleStyles={{ fontSize: convertPxToRem(16), fontWeight: 600 }}
                shouldShowPrice
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
