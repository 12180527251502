import { FormEvent, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import Panel from "../Panel";
import { useTranslation } from "react-i18next";
import { defaultFullscreenPageStyling } from "../../theme";
import CircledBackButton from "../CircledBackButton";
import {
  getCommunicationConsent,
  skipCommunicationConsent,
  updateCommunicationConsent,
} from "../../services/core-api-adapter";
import { useNavigate } from "react-router";
import SuccessConfirmationModal from "../SuccessConfirmationModal";
import UpdateDetailsModal from "../UpdateDetailsModal";
import CommunicationPreferencesInputs, {
  CommunicationPreferencesOutputItem,
} from "../CommunicationPreferencesInputs";
import FormInputErrorList from "../FormInputErrorList";
import LoadingSpinner from "../LoadingSpinner";
import ButtonWithAnalytics from "../ButtonWithAnalytics";

function onlyIfMounted(isComponentMounted: boolean, callBack: any) {
  if (isComponentMounted) {
    callBack();
  }
}

export default function CommunicationPreferences() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [communicationItems, setCommunicationItems] = useState<
    CommunicationPreferencesOutputItem[]
  >([]);

  const [isFetchingOrUpdating, setIsFetchingOrUpdating] = useState(true);
  const [isValuesFormSubmitEnabled, setIsValuesFormSubmitEnabled] =
    useState(true);
  const [isUpdateError, setIsUpdateError] = useState(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isOptingOutOpen, setIsOptingOutOpen] = useState(false);

  function goBack() {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/");
    }
  }

  function onSuccessConfirmationModalClose() {
    goBack();
  }

  function onCancelButtonClick() {
    goBack();
  }

  useEffect(() => {
    let isComponentMounted = true;

    setIsFetchingOrUpdating(true);
    getCommunicationConsent().then((response) => {
      onlyIfMounted(isComponentMounted, () => {
        setIsFetchingOrUpdating(false);
        setCommunicationItems(response.communicationItems);
      });
    });

    return () => {
      isComponentMounted = false;
    };
  }, []);

  useEffect(() => {
    const isEveryActiveCommunicationItemValid = communicationItems
      .filter((item) => item.isActive === true)
      .every((item) => item.isValid === true);

    setIsValuesFormSubmitEnabled(
      isEveryActiveCommunicationItemValid && !isFetchingOrUpdating
    );
  }, [communicationItems, isFetchingOrUpdating]);

  function onValuesFormChange(value: CommunicationPreferencesOutputItem[]) {
    setCommunicationItems(value);
  }

  function onConfirmOptOut() {
    setIsOptingOutOpen(false);
    submit();
  }

  function onValuesFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const isAnyCommunicationItemActive = communicationItems.some(
      (item) => item.isActive === true
    );

    if (isAnyCommunicationItemActive) {
      setIsOptingOutOpen(false);
      submit();
    } else {
      setIsOptingOutOpen(true);
    }
  }

  function submit() {
    const isEveryCommunicationItemInactive = communicationItems.every(
      (item) => item.isActive === false
    );

    setIsFetchingOrUpdating(true);
    setIsUpdateError(false);

    if (isEveryCommunicationItemInactive) {
      skipCommunicationConsent()
        .then(() => {
          setIsSuccessModalOpen(true);
        })
        .catch(() => {
          setIsUpdateError(true);
        })
        .finally(() => {
          setIsFetchingOrUpdating(false);
        });
    } else {
      updateCommunicationConsent(
        communicationItems.map((communicationItem) => {
          return {
            communicationType: communicationItem.communicationType,
            communicationValue: communicationItem.communicationValue,
            isActive: communicationItem.isActive,
          };
        })
      )
        .then(() => {
          setIsSuccessModalOpen(true);
        })
        .catch(() => {
          setIsUpdateError(true);
        })
        .finally(() => {
          setIsFetchingOrUpdating(false);
        });
    }
  }

  return (
    <Stack sx={{ ...defaultFullscreenPageStyling }} spacing={4}>
      <CircledBackButton showLabel={true} />
      <Stack spacing={1}>
        <Typography variant="h2">
          {t("CommunicationPreferences.title")}
        </Typography>
        <Typography>{t("CommunicationPreferences.subtitle")}</Typography>
      </Stack>

      <Panel>
        {isFetchingOrUpdating && (
          <Stack alignItems="center">
            <LoadingSpinner />
          </Stack>
        )}

        {!isFetchingOrUpdating && (
          <form onSubmit={onValuesFormSubmit}>
            <Stack spacing={3}>
              <CommunicationPreferencesInputs
                onChange={onValuesFormChange}
                value={communicationItems}
              />
              {isUpdateError ? (
                <FormInputErrorList errors={[t("common.somethingWentWrong")]} />
              ) : null}
              <Stack direction="row" spacing={1}>
                <ButtonWithAnalytics
                  page="Communication settings"
                  text={t("common.cancelButton")}
                  intent="navigational"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={onCancelButtonClick}
                >
                  {t("common.cancelButton")}
                </ButtonWithAnalytics>
                <ButtonWithAnalytics
                  page="Communication settings"
                  text={t("common.saveButton")}
                  intent="navigational"
                  type="submit"
                  color="primary"
                  disabled={!isValuesFormSubmitEnabled}
                  fullWidth
                >
                  {t("common.saveButton")}
                </ButtonWithAnalytics>
              </Stack>
            </Stack>
          </form>
        )}
      </Panel>

      <SuccessConfirmationModal
        isOpen={isSuccessModalOpen}
        onClose={onSuccessConfirmationModalClose}
        body={[t("CommunicationPreferences.successModal.title")]}
        label={t("CommunicationPreferences.successModal.title")}
      />
      <UpdateDetailsModal
        title={t("CommunicationPreferencesOptOutModal.title")}
        content={t("CommunicationPreferencesOptOutModal.subtitle")}
        buttonText={t("common.optOut")}
        isOpen={isOptingOutOpen}
        onClose={() => {
          setIsOptingOutOpen(false);
        }}
        onSubmit={() => onConfirmOptOut()}
      />
    </Stack>
  );
}
