import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IdentityDocumentType } from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { AuthIdentificationType, useGlobalStore } from "@/store";
import { convertPxToRem, replaceSubjectWithString } from "@/utils";
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import IconLoader from "@/components/IconLoader";
import { FaceIconBrandLarge } from "@/theme/icons";
import GetHelpCTAButton from "@/components/GetHelpCTAButton";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";
import { GetHelpFABConfig } from "@/theme";

export interface AuthIdentificationTypeOption {
  id: AuthIdentificationType;
  label: string;
  icon?: any;
  identityDocumentSubType?: string;
}

interface AuthIdentificationTypeSelectionProps {
  onContinue: (selectedAuthIdentificationType: string) => void;
}

export default function AuthIdentificationTypeSelection({
  onContinue,
}: AuthIdentificationTypeSelectionProps) {
  const { t } = useTranslation();
  const [selectedAuthIdentificationType, setSelectedAuthIdentificationType] =
    useState<AuthIdentificationTypeOption | null>(null);

  const { dispatch, state } = useGlobalStore();

  useEffect(() => {
    const userSelectedAuthIdentificationType =
      state.userSelectedAuthIdentificationType.value;
    if (
      isFeatureEnabled(
        FeatureToggleIdentifier.ENABLE_AUTH_IDENTIFICATION_TYPE_SELECTION
      )
    ) {
      if (userSelectedAuthIdentificationType) {
        onContinue(userSelectedAuthIdentificationType);
      }
    } else {
      onContinue("");
    }
  }, []);

  const authIdentificationTypes: AuthIdentificationTypeOption[] = [
    {
      id: AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT,
      label: t("common.IdNumber"),
      identityDocumentSubType: "IdNumber",
    },
    {
      id: AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT,
      label: t("common.PassportNumber"),
      identityDocumentSubType: "PassportNumber",
    },
    {
      id: AuthIdentificationType.UNUID,
      label: t(
        `AuthIdentificationTypeSelection.options.${replaceSubjectWithString(
          AuthIdentificationType.UNUID,
          IdentityDocumentType.UNUID,
          "UNUID"
        )}.buttonLabel` as any
      ),
      identityDocumentSubType: "UnuId",
    },
  ];

  const onContinueButtonClick = () => {
    const authenticationIdType = selectedAuthIdentificationType?.id || "";
    const authenticationIdSubType =
      selectedAuthIdentificationType?.identityDocumentSubType || "";

    dispatch({
      type: "SET_USER_AUTH_IDENTIFICATION_TYPE",
      payload: {
        userSelectedAuthIdentificationType: authenticationIdType,
        userSelectedAuthIdentificationSubType: authenticationIdSubType,
      },
    });

    onContinue(authenticationIdType);
  };

  const onUNUIDButtonClick = () => {
    const authenticationIdType = AuthIdentificationType.UNUID;
    const authenticationIdSubType = "UnuId";

    dispatch({
      type: "SET_USER_AUTH_IDENTIFICATION_TYPE",
      payload: {
        userSelectedAuthIdentificationType: authenticationIdType,
        userSelectedAuthIdentificationSubType: authenticationIdSubType,
      },
    });

    onContinue(authenticationIdType);
  };

  return (
    <>
      <Stack
        justifyContent="space-between"
        flexGrow={1}
        sx={{
          p: 2,
          minHeight: "100%",
        }}
      >
        <Stack flexDirection="row" justifyContent="space-between">
          <IconLoader
            icon="UnuLogo"
            color="primary"
            sx={{
              fontSize: convertPxToRem(90),
            }}
          />
        </Stack>

        <Stack>
          <Stack alignItems="center" spacing={2} pb={2}>
            <FaceIconBrandLarge />
            <Typography variant="h2" component="h1" textAlign="center">
              {t("AuthIdentificationTypeSelectionV2.title")}
            </Typography>
            <Typography variant="body1" color="neutral.main" textAlign="center">
              {t("AuthIdentificationTypeSelectionV2.subtitle")}
            </Typography>
          </Stack>

          <Stack spacing={1} pb={2}>
            <Stack spacing={2} p={2} flexDirection="row">
              <ToggleButtonGroup
                color="neutral"
                value={selectedAuthIdentificationType?.identityDocumentSubType}
                onChange={(event, value) => {
                  const selectedType = authIdentificationTypes.find(
                    (type) => type.identityDocumentSubType === value
                  );
                  if (selectedType) {
                    setSelectedAuthIdentificationType({
                      id: selectedType.id,
                      label: selectedType.label,
                      icon: selectedType.icon,
                      identityDocumentSubType:
                        selectedType.identityDocumentSubType,
                    });
                  }
                }}
                fullWidth
                exclusive
              >
                {authIdentificationTypes.map(
                  (authIdentificationType: AuthIdentificationTypeOption) => {
                    if (authIdentificationType.id === "UNU_ID") {
                      return null;
                    }
                    return (
                      <ToggleButton
                        key={
                          authIdentificationType.label +
                          authIdentificationType.identityDocumentSubType
                        }
                        value={
                          authIdentificationType.identityDocumentSubType as any
                        }
                      >
                        {authIdentificationType.label}
                      </ToggleButton>
                    );
                  }
                )}
              </ToggleButtonGroup>
            </Stack>
            <Stack textAlign="center">
              {authIdentificationTypes.map(
                (authIdentificationType: AuthIdentificationTypeOption) => {
                  if (authIdentificationType.id !== "UNU_ID") {
                    return null;
                  }
                  return (
                    <ButtonWithAnalytics
                      page="AuthIdentificationTypeSelection"
                      text={t("AuthIdentificationTypeSelection.UnuId")}
                      intent="navigational"
                      key={authIdentificationType.id}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        onUNUIDButtonClick();
                      }}
                    >
                      {t("AuthIdentificationTypeSelection.UnuId")}
                    </ButtonWithAnalytics>
                  );
                }
              )}
            </Stack>
          </Stack>

          <Stack spacing={1} alignItems="center" pb={2}>
            <IconLoader icon="PadlockIcon" />
            <Typography textAlign="center" variant="body2">
              {t("AuthIdentificationTypeSelectionV2.footer")}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignSelf="flex-end"
            position="relative"
            width={GetHelpFABConfig.floatingActionButtonWidth}
          >
            <GetHelpCTAButton
              pageName="AuthIdentificationTypeSelection"
              eventName="action.contactSupportFab"
            />
          </Stack>
          <ButtonWithAnalytics
            page="AuthIdentificationTypeSelection"
            text={t("common.nextButton")}
            intent="navigational"
            disabled={!selectedAuthIdentificationType}
            onClick={onContinueButtonClick}
          >
            {t("common.nextButton")}
          </ButtonWithAnalytics>
        </Stack>
      </Stack>
    </>
  );
}
