import { Stack, Typography } from "@mui/material";
import PathologyOrderSummary from ".";
import MaxWidthContainer from "../MaxWidthContainer";

export default function PathologyOrderSummaryExamples() {
  return (
    <MaxWidthContainer>
      <Typography variant="h3" color="primary" textAlign="center" mb={2}>
        Pathology Order Summary (Not pending)
      </Typography>
      <Stack height="100vh">
        <PathologyOrderSummary
          orderItem="HIV Screening"
          orderDate="2027-04-19T13:42:36.521883+00:00"
          amountFormatted="R200"
          isOrderPending={false}
        />
      </Stack>
      <Typography variant="h3" color="primary" textAlign="center" mb={2}>
        Pathology Order Summary (pending)
      </Typography>
      <Stack height="100vh">
        <PathologyOrderSummary
          orderItem="HIV Screening"
          orderDate="2027-04-19T13:42:36.521883+00:00"
          amountFormatted="R200"
          isOrderPending={true}
        />
      </Stack>
    </MaxWidthContainer>
  );
}
