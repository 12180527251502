import {
  trackFormEvent,
  AnalyticsEvent,
  trackEvent,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import {
  skipCommunicationConsent,
  updateCommunicationConsent,
} from "@/services/core-api-adapter";
import { useGlobalStore } from "@/store";
import { GetHelpFABConfig } from "@/theme";
import { Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useState, useEffect, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import CollectConsentCommonContent from "@/components/CollectConsentCommonContent";
import CommunicationPreferencesInputs, {
  CommunicationPreferencesOutputItem,
  CommunicationPreferencesItem,
} from "@/components/CommunicationPreferencesInputs";
import FormInputErrorList from "@/components/FormInputErrorList";
import FullscreenLoadingIndicator from "@/components/FullscreenLoadingIndicator";
import GetHelpCTAButton from "@/components/GetHelpCTAButton";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";

interface CollectMarketingCommunicationPreferencesProps {
  onSubmit: (payload?: any) => void;
  phoneNumber?: string;
}

export default function CollectMarketingCommunicationPreferences(
  props: CollectMarketingCommunicationPreferencesProps
) {
  const { state } = useGlobalStore();
  const { t } = useTranslation();

  const [isUpdating, setIsUpdating] = useState(false);
  const [optInValue, setOptInValue] = useState<null | boolean>(null);
  const [isOptInFormSubmitEnabled, setIsOptInFormSubmitEnabled] =
    useState(false);
  const [isValuesFormVisible, setIsValuesFormVisible] = useState(false);
  const [isUpdateError, setIsUpdateError] = useState(false);

  const [communicationItems, setCommunicationItems] = useState<
    CommunicationPreferencesOutputItem[]
  >(
    props.phoneNumber
      ? [
          {
            communicationType: "SMS",
            communicationValue: props.phoneNumber,
            isActive: true,
            isValid: true,
          },
        ]
      : []
  );

  const [isValuesFormSubmitEnabled, setIsValuesFormSubmitEnabled] =
    useState(false);

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Marketing preferences",
    });
  }, []);

  useEffect(() => {
    const isEveryActiveCommunicationItemValid = communicationItems
      .filter((item) => item.isActive === true)
      .every((item) => item.isValid === true);

    const isEveryCommunicationItemInactive = communicationItems.every(
      (item) => item.isActive === false
    );

    setIsOptInFormSubmitEnabled(optInValue !== null);
    setIsValuesFormSubmitEnabled(
      isEveryCommunicationItemInactive || isEveryActiveCommunicationItemValid
    );
  }, [optInValue, communicationItems]);

  function onOptInFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (optInValue === true) {
      setIsValuesFormVisible(true);
    } else {
      onSkipCommunicationConsentRequested();
    }
  }

  function onValuesFormChange(value: CommunicationPreferencesOutputItem[]) {
    setCommunicationItems(value);
  }

  function onSkipCommunicationConsentRequested() {
    setIsUpdating(true);
    skipCommunicationConsent()
      .then(() => {
        trackEvent({
          event: "action.marketingCommunicationPreferencesSkipped",
          source: "marketingCommunicationPreferences",
        });
        trackUserInteraction({
          linkText: "Marketing consent screen | Skip",
          linkIntent: "confirmational",
          linkScope: "button",
        });
        onDone();
      })
      .catch(() => {
        onError();
      });
  }

  function onSubmitCommunicationConsentRequested(
    communicationItemsToSubmit: CommunicationPreferencesItem[]
  ) {
    setIsUpdateError(false);
    setIsUpdating(true);
    updateCommunicationConsent(communicationItemsToSubmit)
      .then(() => {
        communicationItemsToSubmit.forEach((item) => {
          trackEvent({
            event: "action.marketingCommunicationPreferencesCollected",
            "marketingCommunicationPreferencesCollected.type":
              item.communicationType,
            source: "marketingCommunicationPreferences",
          });
          trackUserInteraction({
            linkText: `Marketing consent screen | ${item.communicationType}`,
            linkIntent: "confirmational",
            linkScope: "button",
          });
        });
        onDone();
      })
      .catch(() => {
        onError();
      });
  }

  function onValuesFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const communicationItemsToSubmit = communicationItems
      .filter((communicationItem) => {
        return communicationItem.isValid === true;
      })
      .map((communicationItem) => {
        return {
          communicationType: communicationItem.communicationType,
          communicationValue: communicationItem.communicationValue,
          isActive: communicationItem.isActive,
        };
      });

    if (communicationItemsToSubmit.length === 0) {
      onSkipCommunicationConsentRequested();
    } else {
      onSubmitCommunicationConsentRequested(communicationItemsToSubmit);
    }
  }

  function onDone() {
    setIsUpdating(false);
    setIsUpdateError(false);
    props.onSubmit();

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Marketing consent",
    });
  }

  function onToggleButtonChange(_: any, newValue: null | boolean) {
    if (newValue !== null) {
      setOptInValue(newValue);
      trackUserInteraction({
        linkText: `CollectMarketingCommunicationPreferences | ${newValue === true ? "Yes" : "No"}`,
        linkIntent: "confirmational",
        linkScope: "button",
      });
    }
  }

  function onError() {
    setIsUpdating(false);
    setIsUpdateError(true);
  }

  return (
    <>
      {isUpdating ? (
        <FullscreenLoadingIndicator />
      ) : (
        <Stack spacing={4} justifyContent="flex-end" sx={{ pb: 2 }}>
          <CollectConsentCommonContent
            titleTextKey={t("CollectMarketingCommunicationPreferences.title")}
            bodyTextKey={"CollectMarketingCommunicationPreferences.body"}
          />

          {isUpdateError ? (
            <FormInputErrorList errors={[t("common.somethingWentWrong")]} />
          ) : null}

          {!isValuesFormVisible && (
            <form onSubmit={onOptInFormSubmit}>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <ToggleButtonGroup
                    color="neutral"
                    size="large"
                    value={optInValue}
                    exclusive
                    onChange={onToggleButtonChange}
                    fullWidth
                  >
                    <ToggleButton value={true}>{t("common.yes")}</ToggleButton>
                    <ToggleButton value={false}>{t("common.no")}</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
                {state.currentUser.isRetailMember === false && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignSelf="flex-end"
                    position="relative"
                    width={GetHelpFABConfig.floatingActionButtonWidth}
                  >
                    <GetHelpCTAButton />
                  </Stack>
                )}
                <ButtonWithAnalytics
                  page="CollectMarketingCommunicationPreferences"
                  text={t("common.nextButton")}
                  intent="navigational"
                  type="submit"
                  color="primary"
                  disabled={!isOptInFormSubmitEnabled}
                  fullWidth
                >
                  {t("common.nextButton")}
                </ButtonWithAnalytics>
              </Stack>
            </form>
          )}

          {isValuesFormVisible && !isUpdating && (
            <form onSubmit={onValuesFormSubmit}>
              <Stack spacing={2}>
                <CommunicationPreferencesInputs
                  onChange={onValuesFormChange}
                  value={communicationItems}
                />
                {state.currentUser.isRetailMember === false && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignSelf="flex-end"
                    position="relative"
                    width={GetHelpFABConfig.floatingActionButtonWidth}
                    pt={2}
                  >
                    <GetHelpCTAButton pageName="CollectMarketingCommunicationPreferences" />
                  </Stack>
                )}
                <ButtonWithAnalytics
                  page="CollectMarketingCommunicationPreferences"
                  text={t("common.continueButton")}
                  intent="navigational"
                  type="submit"
                  color="primary"
                  disabled={!isValuesFormSubmitEnabled}
                  fullWidth
                >
                  {t("common.continueButton")}
                </ButtonWithAnalytics>
              </Stack>
            </form>
          )}
        </Stack>
      )}
    </>
  );
}
