import { assign, fromPromise, setup } from "xstate";

import {
  getMember,
  GetMemberSuccessResponseData,
  loginOTP,
  loginThirdPartyUser,
} from "../../services/core-api-adapter";

export const thirdPartyAuthFlowEventNames = {
  GO_BACK: "GO_BACK",
  USER_DETAILS_COLLECTED: "USER_DETAILS_COLLECTED",
  SKIP_OTP_COLLECTION: "SKIP_OTP_COLLECTION",
  OTP_COLLECTED: "OTP_COLLECTED",
  RESEND_OTP: "RESEND_OTP",
  TRY_AGAIN: "TRY_AGAIN",
  LOGIN_BUTTON_CLICK: "LOGIN_BUTTON_CLICK",
};

interface Context {
  authenticationType: string;
  totalSteps: number;
  currentStepValue: number;
  isBackTransitionAllowed: boolean;
  userDetails: {
    firstName: string;
    lastName: string;
    phoneNumber: { globalSubscriberNumber: string; countryCode: string };
  };
  otp: string;
  loginResponse: {
    cognitoUserId: string;
    sessionId: string;
    authenticationResult: any;
  };
  loginResponseErrors: any[];
  loginOTPResponseErrors: any[];
  loginOTPResponse: any;
  getMemberResponse: GetMemberSuccessResponseData | null;
  getMemberError: any;
}

const initialContextValues: Context = {
  authenticationType: "",
  totalSteps: 4,
  currentStepValue: 1,
  isBackTransitionAllowed: true,
  userDetails: {
    firstName: "",
    lastName: "",
    phoneNumber: { globalSubscriberNumber: "", countryCode: "" },
  },
  otp: "",
  loginResponse: {
    cognitoUserId: "",
    sessionId: "",
    authenticationResult: null,
  },
  loginResponseErrors: [],
  loginOTPResponseErrors: [],
  loginOTPResponse: null,
  getMemberResponse: null,
  getMemberError: null,
};

export const thirdPartyAuthFlowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actions: {
    addUserDetailsToContext: assign(({ event }) => {
      if (event.type === thirdPartyAuthFlowEventNames.USER_DETAILS_COLLECTED) {
        return {
          userDetails: event.info,
        };
      }
      return {};
    }),

    addLoginResponseErrorsToContext: assign(({ event }) => {
      return {
        loginResponseErrors: [event.error.message].filter(
          (error) => error !== ""
        ),
      };
    }),

    removeLoginResponseErrorsFromContext: assign(() => {
      return {
        loginResponseErrors: [],
      };
    }),

    addLoginResponseToContext: assign(({ event }) => {
      return {
        loginResponse: {
          cognitoUserId: event.output.cognitoUserId,
          sessionId: event.output.sessionId,
          authenticationResult: event.output.authenticationResult,
        },
      };
    }),

    addOTPToContext: assign(({ event }) => {
      if (event.type === thirdPartyAuthFlowEventNames.OTP_COLLECTED) {
        return {
          otp: event.info,
        };
      }
      return {};
    }),

    addLoginOTPResponseErrorsToContext: assign(({ event }) => {
      return {
        loginOTPResponseErrors: [event.error.message],
      };
    }),

    removeLoginOTPResponseErrorsFromContext: assign(() => {
      return {
        loginOTPResponseErrors: [],
      };
    }),

    addLoginOTPResponseToContext: assign(({ event }) => {
      return {
        loginOTPResponse: event.output,
      };
    }),

    removeLoginOTPResponseFromContext: assign(() => {
      return {
        loginOTPResponse: null,
      };
    }),
  },
  actors: {
    fetchMember: fromPromise(async () => {
      return await getMember();
    }),

    loginUser: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return await loginThirdPartyUser(input.context.userDetails);
      }
    ),

    loginOTP: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return await loginOTP({
          sessionID: input.context.loginResponse.sessionId,
          cognitoUserId: input.context.loginResponse.cognitoUserId,
          mfaCode: input.context.otp,
          phoneNumber: input.context.userDetails.phoneNumber,
        });
      }
    ),
  },
}).createMachine({
  context: initialContextValues,
  id: "thirdPartyAuthFlow",
  initial: "collectingUserDetails",
  states: {
    collectingUserDetails: {
      entry: assign({ currentStepValue: 1, isBackTransitionAllowed: true }),
      on: {
        GO_BACK: {
          target: "exit",
        },
        LOGIN_BUTTON_CLICK: {
          target: "collectingLoginDetails",
        },
        USER_DETAILS_COLLECTED: {
          target: "verifyingIdentificationCredentials",
          actions: {
            type: "addUserDetailsToContext",
          },
        },
      },
      exit: {
        type: "removeLoginResponseErrorsFromContext",
      },
    },

    collectingLoginDetails: {
      entry: assign({ currentStepValue: 1, isBackTransitionAllowed: true }),
      on: {
        GO_BACK: {
          target: "collectingUserDetails",
        },
        USER_DETAILS_COLLECTED: {
          target: "verifyingIdentificationCredentials",
          actions: {
            type: "addUserDetailsToContext",
          },
        },
      },
      exit: {
        type: "removeLoginResponseErrorsFromContext",
      },
    },

    verifyingIdentificationCredentials: {
      entry: assign({ isBackTransitionAllowed: false }),
      invoke: {
        id: "verifyingIdentificationCredentials",
        src: "loginUser",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "collectingOTP",
          actions: {
            type: "addLoginResponseToContext",
          },
        },
        onError: {
          target: "collectingOTP",
        },
      },
    },

    collectingOTP: {
      on: {
        GO_BACK: {
          target: "collectingUserDetails",
        },
        OTP_COLLECTED: {
          target: "verifyingOTP",
          actions: {
            type: "addOTPToContext",
          },
        },
        RESEND_OTP: {
          target: "resendOTP",
        },
        SKIP_OTP_COLLECTION: {
          target: "gettingMember",
        },
      },
      entry: assign({ currentStepValue: 1, isBackTransitionAllowed: true }),
      exit: [
        {
          type: "removeLoginOTPResponseErrorsFromContext",
        },
        {
          type: "removeLoginResponseErrorsFromContext",
        },
      ],
    },

    verifyingOTP: {
      entry: {
        type: "removeLoginOTPResponseFromContext",
      },
      invoke: {
        id: "verifyingOTP",
        src: "loginOTP",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "gettingMember",
          actions: {
            type: "addLoginOTPResponseToContext",
          },
        },
        onError: {
          target: "collectingOTP",
          actions: {
            type: "addLoginOTPResponseErrorsToContext",
          },
        },
      },
    },

    resendOTP: {
      invoke: {
        id: "resendOTP",
        src: "loginUser",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "collectingOTP",
          actions: {
            type: "addLoginResponseToContext",
          },
        },
        onError: {
          target: "collectingOTP",
          actions: {
            type: "addLoginResponseErrorsToContext",
          },
        },
      },
    },

    gettingMember: {
      invoke: {
        id: "getMember",
        src: "fetchMember",
        onDone: {
          target: "done",
          actions: assign({
            getMemberResponse: ({ event }) => {
              return "cognitoUserId" in event.output ? event.output : null;
            },
          }),
        },
        onError: {
          target: "gettingMemberErrorGeneric",
        },
      },
    },

    gettingMemberErrorGeneric: {
      on: {
        TRY_AGAIN: {
          target: "exit",
        },
      },
    },

    exit: {
      type: "final",
    },

    done: {
      type: "final",
    },
  },
});
