import { useTranslation } from "react-i18next";
import { Stack, SxProps, Typography } from "@mui/material";
import theme from "../../theme";
import IconLoader from "../IconLoader";

export enum PathologyOrderStatus {
  PAID = "PAID",
  ORDER_PROCESSED = "ORDER_PROCESSED",
  TEST_TO_BE_COMPLETED = "TEST_TO_BE_COMPLETED",
}

interface PathologyStatusIndicatorProps {
  status: PathologyOrderStatus;
  overrideStyles?: SxProps;
}

const statusViewModels = {
  PAID: {
    icon: (
      <IconLoader
        icon="CheckInCircleIcon"
        sx={{
          fontSize: 16,
          color: "accent1.main",
        }}
      />
    ),
    textColour: "accent1.main",
    borderColor: theme.palette.accent1.main,
    backgroundColour: "accent1.contrastText",
  },
  ORDER_PROCESSED: {
    icon: (
      <IconLoader
        icon="CheckInCircleIcon"
        sx={{
          fontSize: 16,
          color: "accent1.main",
        }}
      />
    ),
    textColour: "accent1.main",
    borderColor: theme.palette.accent1.main,
    backgroundColour: "accent1.contrastText",
  },
  TEST_TO_BE_COMPLETED: {
    icon: (
      <IconLoader
        icon="CheckInShieldIcon"
        sx={{
          fontSize: 16,
          color: "accent1.main",
        }}
      />
    ),
    textColour: "accent1.main",
    borderColor: theme.palette.accent1.main,
    backgroundColour: "accent1.contrastText",
  },
  UNKNOWN: {
    icon: (
      <IconLoader
        icon="InformationSymbolInCircleIcon"
        color={"neutral"}
        sx={{ fontSize: 16 }}
      />
    ),
    textColour: "neutral.main",
    borderColor: theme.palette.neutral.main,
    backgroundColour: "neutral.contrastText",
  },
};

export default function PathologyStatusIndicator({
  status,
  overrideStyles,
}: PathologyStatusIndicatorProps) {
  const { t } = useTranslation();

  const statusData =
    status in statusViewModels
      ? statusViewModels[status]
      : statusViewModels.UNKNOWN;
  const text: string | undefined =
    status in statusViewModels
      ? t(`common.pathologyOrderStatus.${PathologyOrderStatus[status]}`)
      : status;

  return (
    <Stack
      display="inline-flex"
      direction="row"
      alignItems="center"
      alignContent="center"
      alignSelf="flex-start"
      borderRadius={1}
      spacing={0.25}
      p={0.5}
      bgcolor={statusData.backgroundColour}
      sx={{
        border: `1px solid ${statusData.borderColor}`,
        height: "auto",
        width: "auto",
        ...overrideStyles,
      }}
    >
      {statusData.icon}
      {text && (
        <Typography
          fontSize={10}
          fontWeight="600"
          color={statusData.textColour}
        >
          {text}
        </Typography>
      )}
    </Stack>
  );
}
