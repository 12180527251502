import WelcomeFlow from "../../components/WelcomeFlow";
import { useGlobalStore } from "../../store";
import ThirdPartyLandingScreen from "./components/ThirdPartyWelcomePage";
import { useNavigate } from "react-router";

export default function WelcomePage() {
  const { state } = useGlobalStore();
  const navigate = useNavigate();

  return (
    <>
      {state.isThirdPartyMember === true && (
        <ThirdPartyLandingScreen
          onContinue={() => {
            navigate("/login");
          }}
        />
      )}

      {state.isThirdPartyMember === false && <WelcomeFlow />}
    </>
  );
}
