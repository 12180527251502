import { useMachine } from "@xstate/react";
import {
  thirdPartyAuthFlowEventNames,
  thirdPartyAuthFlowMachine,
} from "./machine";
import { useEffect } from "react";
import { Box, Fade, Stack } from "@mui/material";
import FlowHeader from "../FlowHeader";
import FormInputErrorList from "../FormInputErrorList";
import {
  getI18nTranslationKeysFromMessages,
  logout,
} from "../../services/core-api-adapter";
import { useTranslation } from "react-i18next";
import CollectThirdPartyUserDetails from "../CollectThirdPartyUserDetails";
import CollectOTP from "../CollectOTP";
import {
  trackEvent,
  trackFormEvent,
  AnalyticsEvent,
  trackUserInteraction,
} from "../../services/analytics-adapter";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";
import { Navigate, useNavigate } from "react-router";
import { useGlobalStore } from "../../store";
import AuthGetMemberError from "../AuthGetMemberError";
import CollectThirdPartyUserLoginDetails from "../CollectThirdPartyUserLoginDetails";

export default function ThirdPartyAuthFlow() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStore();

  const [authFlowMachineState, dispatchAuthFlowMachineEvent] = useMachine(
    thirdPartyAuthFlowMachine
  );

  function onBackButtonClick() {
    dispatchAuthFlowMachineEvent({
      type: thirdPartyAuthFlowEventNames.GO_BACK,
    });
  }

  function onUserDetailsCollected(userData: {
    phoneNumber: { globalSubscriberNumber: string; countryCode: string };
    firstName: string;
    lastName: string;
  }) {
    dispatchAuthFlowMachineEvent({
      type: thirdPartyAuthFlowEventNames.USER_DETAILS_COLLECTED,
      info: userData,
    });
  }

  function onUserLoginDetailsCollected(userData: {
    phoneNumber: { globalSubscriberNumber: string; countryCode: string };
  }) {
    dispatchAuthFlowMachineEvent({
      type: thirdPartyAuthFlowEventNames.USER_DETAILS_COLLECTED,
      info: userData,
    });
  }

  function onCollectOTPSubmit(otpValue: string) {
    dispatchAuthFlowMachineEvent({
      type: thirdPartyAuthFlowEventNames.OTP_COLLECTED,
      info: otpValue,
    });
    trackEvent({
      event: "action.OTPCollected",
      source: "Church of Uganda Auth Flow",
    });

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Church of Uganda Enter OTP",
    });
  }

  function resendOTP() {
    dispatchAuthFlowMachineEvent({
      type: thirdPartyAuthFlowEventNames.RESEND_OTP,
    });
    trackEvent({
      event: "action.resendOTP",
      source: "Church of Uganda Auth Flow",
    });

    trackUserInteraction({
      linkText: "Church of Uganda OTP Screen | resendOTP",
      linkIntent: "confirmational",
      linkScope: "button",
    });
  }

  function tryAgain() {
    logout();
    dispatchAuthFlowMachineEvent({
      type: thirdPartyAuthFlowEventNames.TRY_AGAIN,
    });
  }

  function onLoginButtonClick() {
    dispatchAuthFlowMachineEvent({
      type: thirdPartyAuthFlowEventNames.LOGIN_BUTTON_CLICK,
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [authFlowMachineState]);

  useEffect(() => {
    if (authFlowMachineState.context?.getMemberResponse?.memberId) {
      dispatch({
        type: "SET_CURRENT_USER",
        payload: authFlowMachineState.context.getMemberResponse,
      });
      dispatch({
        type: "SET_IS_USER_LOGGED_IN",
        payload: true,
      });
      navigate("/home");
    }

    if (authFlowMachineState.context.loginResponse.authenticationResult) {
      dispatchAuthFlowMachineEvent({
        type: thirdPartyAuthFlowEventNames.SKIP_OTP_COLLECTION,
      });
    }
  }, [
    authFlowMachineState.context.loginResponse,
    authFlowMachineState.context.getMemberResponse,
    authFlowMachineState.context.getMemberError,
    authFlowMachineState.context?.loginOTPResponseErrors,
    authFlowMachineState.context?.loginResponseErrors,
    dispatch,
    t,
    navigate,
  ]);

  useEffect(() => {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
    });
  }, []);

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{ height: "100%", overflowX: "hidden" }}
    >
      {authFlowMachineState.matches("gettingMemberErrorGeneric") ? (
        <AuthGetMemberError
          phoneNumber={authFlowMachineState.context.userDetails.phoneNumber}
          tryAgain={tryAgain}
        />
      ) : (
        <>
          <FlowHeader
            title={t("AuthFlow.title")}
            onBackButtonClick={onBackButtonClick}
            value={authFlowMachineState.context.currentStepValue}
            max={authFlowMachineState.context.totalSteps}
            isBackButtonVisible={
              authFlowMachineState.context.isBackTransitionAllowed
            }
          />
          <Box sx={{ p: 2 }}>
            <Box sx={{ mb: 2 }}>
              <FormInputErrorList
                errors={getI18nTranslationKeysFromMessages(
                  authFlowMachineState.context.loginResponseErrors
                ).map((translationKey: any) => {
                  return t(translationKey);
                })}
              />
            </Box>
            {authFlowMachineState.matches("collectingUserDetails") && (
              <Fade in={true}>
                <Stack>
                  <CollectThirdPartyUserDetails
                    existingUserDetails={
                      authFlowMachineState.context.userDetails
                    }
                    onSubmit={onUserDetailsCollected}
                    onLoginClick={onLoginButtonClick}
                  />
                </Stack>
              </Fade>
            )}
            {authFlowMachineState.matches("collectingLoginDetails") && (
              <Fade in={true}>
                <Stack>
                  <CollectThirdPartyUserLoginDetails
                    existingUserDetails={
                      authFlowMachineState.context.userDetails
                    }
                    onSubmit={onUserLoginDetailsCollected}
                  />
                </Stack>
              </Fade>
            )}
            {authFlowMachineState.matches("collectingOTP") && (
              <Fade in={true}>
                <div>
                  <CollectOTP
                    errors={getI18nTranslationKeysFromMessages(
                      authFlowMachineState.context.loginOTPResponseErrors
                    ).map((translationKey: any) => {
                      return t(translationKey);
                    })}
                    phoneNumber={
                      authFlowMachineState.context.userDetails.phoneNumber
                    }
                    onSubmit={onCollectOTPSubmit}
                    resendOTP={resendOTP}
                  />
                </div>
              </Fade>
            )}

            {(authFlowMachineState.matches("verifyingOTP") ||
              authFlowMachineState.matches("resendOTP") ||
              authFlowMachineState.matches("gettingMember")) && (
              <FullscreenLoadingIndicator isOpen={true} mainIconType="face" />
            )}

            {authFlowMachineState.matches("exit") && (
              <Navigate to="/"></Navigate>
            )}
          </Box>
        </>
      )}
    </Stack>
  );
}
